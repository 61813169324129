import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let SearchLoader = class SearchLoader extends Vue {
};
__decorate([
    Prop({ type: Number, required: false, default: 10 })
], SearchLoader.prototype, "numberOfRows", void 0);
SearchLoader = __decorate([
    Component({
        name: 'SearchLoader'
    })
], SearchLoader);
export { SearchLoader };
export default SearchLoader;

import { __decorate } from "tslib";
import { Addon, prepareImage } from '@d24/modules/front';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { SiteServiceType } from '@service/site';
import { DateTimeType, EventbusType } from '@movecloser/front-core';
/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let SearchResult = class SearchResult extends Vue {
    constructor() {
        super(...arguments);
        this.file = null;
        this.labels = [];
    }
    sendEvent() {
        this.eventBus.emit('search:click', {
            keyword: this.query,
            position: this.position,
            url: `${window.origin}/${this.model.urlPath}`
        });
    }
    /**
     * Load fallbackImage according to current site
     */
    get fallbackImage() {
        const site = this.siteService.getActiveSite();
        if (!site.properties.fallback || typeof site.properties.fallback !== 'string') {
            return require('@/assets/images/placeholders/image.png');
        }
        return site.properties.fallback;
    }
    /**
     * Get article lead form model
     */
    get lead() {
        return this.model.getProperty(Addon.Lead) || '';
    }
    /**
     * Checking if `SneakPeak` has category
     */
    canShowCategory() {
        return (typeof this.model.parent !== 'undefined' &&
            this.model.parent !== null &&
            typeof this.model.parent.name === 'string' &&
            this.model.parent.name.length > 0);
    }
    /**
     * Checks whether the passed-in `SneakPeak` has properly configured `thumbnail` property.
     */
    hasThumbnail() {
        const modelCover = this.model.getProperty(Addon.Cover);
        return !(typeof modelCover !== 'object' || modelCover === null ||
            typeof modelCover.image !== 'object' || modelCover.image === null);
    }
    /**
     * Resolves the applicable `Image` for the current screen resolution.
     */
    async resolveImage() {
        if (!this.hasThumbnail()) {
            return;
        }
        const modelCover = this.model.getProperty(Addon.Cover);
        if (typeof modelCover === 'object' && modelCover.image) {
            this.relatedServices.resolve(modelCover.image).then(image => {
                this.file = prepareImage(image);
            });
        }
    }
    /**
     * Resolves the SneakPeak labels.
     */
    async resolveLabels() {
        const modelLabels = this.model.getProperty(Addon.Labels);
        if (!modelLabels || !Array.isArray(modelLabels)) {
            return;
        }
        for (const l of modelLabels) {
            if (typeof l === 'object' && l !== null) {
                this.relatedServices.resolve(l).then(label => {
                    this.labels.push(label);
                });
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: false,
        default: ''
    })
], SearchResult.prototype, "query", void 0);
__decorate([
    Prop({
        type: Number,
        required: false,
        default: 0
    })
], SearchResult.prototype, "position", void 0);
__decorate([
    Prop({
        type: Object,
        required: true
    })
], SearchResult.prototype, "model", void 0);
__decorate([
    Prop({
        type: Object,
        required: true
    })
], SearchResult.prototype, "relatedServices", void 0);
__decorate([
    Inject(SiteServiceType)
], SearchResult.prototype, "siteService", void 0);
__decorate([
    Inject(DateTimeType)
], SearchResult.prototype, "dateTime", void 0);
__decorate([
    Inject(EventbusType)
], SearchResult.prototype, "eventBus", void 0);
SearchResult = __decorate([
    Component({
        name: 'SearchResult',
        mounted() {
            this.resolveImage();
            // this.resolveLabels() // https://redmine.movecloser.pl/issues/42154
        }
    })
], SearchResult);
export { SearchResult };
export default SearchResult;
